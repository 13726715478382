// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-thanks-js": () => import("./../../../src/pages/contact-us/thanks.js" /* webpackChunkName: "component---src-pages-contact-us-thanks-js" */),
  "component---src-templates-about-us-page-js": () => import("./../../../src/templates/about-us-page.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-contact-us-page-js": () => import("./../../../src/templates/contact-us-page.js" /* webpackChunkName: "component---src-templates-contact-us-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-our-work-page-js": () => import("./../../../src/templates/our-work-page.js" /* webpackChunkName: "component---src-templates-our-work-page-js" */),
  "component---src-templates-port-carling-page-js": () => import("./../../../src/templates/port-carling-page.js" /* webpackChunkName: "component---src-templates-port-carling-page-js" */),
  "component---src-templates-press-page-js": () => import("./../../../src/templates/press-page.js" /* webpackChunkName: "component---src-templates-press-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-toronto-page-js": () => import("./../../../src/templates/toronto-page.js" /* webpackChunkName: "component---src-templates-toronto-page-js" */)
}

